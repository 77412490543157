<template>
  <v-app id="inspire">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Login form</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                      v-model="username"
                      prepend-icon="mdi-account"
                      name="login"
                      label="Login"
                      type="text"
                  ></v-text-field>
                  <v-text-field
                      v-model="password"
                      id="password"
                      prepend-icon="mdi-lock"
                      name="password"
                      label="Password"
                      type="password"
                  ></v-text-field>
                </v-form>
                <v-alert
                    border="top"
                    color="red lighten-2"
                    dark
                    v-show="errorCredential"
                >
                  Credenciales Invalidas
                </v-alert>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="login">Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>

</template>

<script>
import AuthService from "@/services/auth.service";

export default {
  name: "Login",
  data(){
    return {
      username: null,
      password: null,
      errorCredential: false,
    }
  },
  mounted() {
    this.$store.commit('setLoading', false)
  },
  methods:{
    login(){
      this.$store.commit('setLoading', true)
      AuthService.login(
        this.username,
        this.password
      ).then(
          response =>{
            AuthService.setUserToken(`${response.data.token_type} ${response.data.access_token}`)
            this.$router.push('/empleados')
          }
      )
      .catch(
          () =>{
            this.errorCredential = true
          }
      )
      .finally(()=>{
        this.$store.commit('setLoading', false)
      })
    }
  }
}
</script>

<style scoped>

</style>